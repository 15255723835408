"use client";
import React, { useLayoutEffect } from "react";
import { gsap } from "gsap";
function Header({ service }) {
  useLayoutEffect(() => {
    const tl = gsap.timeline();
    tl.fromTo(".header", { y: 0 }, { y: 0 }, "+=2.5");
    tl.fromTo(
      ".header .container",
      { opacity: 0, translateY: 40 },
      { opacity: 1, translateY: 0 },
      "-=0"
    );

    // Cleanup function
    return () => tl.kill();
  }, []);

  return (
    <div
      className="header page-header-cerv bg-img section-padding"
      style={{
        backgroundImage: `url(${
          service?.banner_image && Object.keys(service?.banner_image).length > 0
            ? service?.banner_image
            : ""
        })`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      data-overlay-dark="4"
    >
      <div className="container pt-100 ontop">
        <div className="text-center">
          <h1 className="fz-100">{service?.service_name}.</h1>
          <div className="mt-15">
            <a href="/dark/home-main">Home</a>
            <span className="padding-rl-20">|</span>
            <span className="main-color">{service?.service_name}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;

import React from "react";

function Numbers({ content }) {
  return (
    <section className="numbers section-padding pt-0">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-3 col-md-6">
            <div className="item d-flex align-items-center justify-content-center md-mb50">
              <h2 className="fz-80 line-height-1">{content?.experience}</h2>
              <span className="sub-title opacity-7 ml-30">
                Years of Experience
              </span>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="item d-flex align-items-center justify-content-center md-mb50">
              <h2 className="fz-80 line-height-1">
                {content?.qualified_staff}
              </h2>
              <span className="sub-title opacity-7 ml-30">
                Qualified <br /> Staff
              </span>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="item d-flex align-items-center justify-content-center">
              <h2 className="fz-80 line-height-1">{content?.customer}</h2>
              <span className="sub-title opacity-7 ml-30">
                Happy Smiling <br /> Customer
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Numbers;

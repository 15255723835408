const CallButtonComp = () => {
  return (
    <div className="CallButton-wrap">
      <a target="_blank" href="tel:+97165616556">
        <img src="/dark/assets/imgs/icon-img/telephone.png" alt="Whatsapp" />
      </a>
    </div>
  );
};

export default CallButtonComp;

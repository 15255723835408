"use client";
import React, { useEffect, useState } from "react";
import ReactCompareImage from "react-compare-image";
import "./galleryStyle.css";

// Dummy data for portfolio items
const portfolioData = [
  {
    id: 1,
    category: "Veneers",
    before:
      "https://cdn-dcchd.nitrocdn.com/PbbVSeOwipRjPKhzLbWmrRNvnSxucHBi/assets/images/optimized/rev-0e398dd/www.micrisdental.com/wp-content/uploads/2023/08/Dr-khaled-Profile-BA-15.jpg",
    after:
      "https://cdn-dcchd.nitrocdn.com/PbbVSeOwipRjPKhzLbWmrRNvnSxucHBi/assets/images/optimized/rev-0e398dd/www.micrisdental.com/wp-content/uploads/2023/08/Dr-khaled-Profile-BA-16.jpg",
  },
  {
    id: 2,
    category: "Crowns",
    before:
      "https://cdn-dcchd.nitrocdn.com/PbbVSeOwipRjPKhzLbWmrRNvnSxucHBi/assets/images/optimized/rev-0e398dd/www.micrisdental.com/wp-content/uploads/2023/08/Dr-khaled-Profile-BA-15.jpg",
    after:
      "https://cdn-dcchd.nitrocdn.com/PbbVSeOwipRjPKhzLbWmrRNvnSxucHBi/assets/images/optimized/rev-0e398dd/www.micrisdental.com/wp-content/uploads/2023/08/Dr-khaled-Profile-BA-16.jpg",
  },
  {
    id: 3,
    category: "Implants",
    before:
      "https://cdn-dcchd.nitrocdn.com/PbbVSeOwipRjPKhzLbWmrRNvnSxucHBi/assets/images/optimized/rev-0e398dd/www.micrisdental.com/wp-content/uploads/2023/08/Dr-khaled-Profile-BA-15.jpg",
    after:
      "https://cdn-dcchd.nitrocdn.com/PbbVSeOwipRjPKhzLbWmrRNvnSxucHBi/assets/images/optimized/rev-0e398dd/www.micrisdental.com/wp-content/uploads/2023/08/Dr-khaled-Profile-BA-16.jpg",
  },
  // {
  //   id: 4,
  //   category: "Implantologists",
  //   before:
  //     "https://cdn-dcchd.nitrocdn.com/PbbVSeOwipRjPKhzLbWmrRNvnSxucHBi/assets/images/optimized/rev-0e398dd/www.micrisdental.com/wp-content/uploads/2023/08/Dr-khaled-Profile-BA-15.jpg",
  //   after:
  //     "https://cdn-dcchd.nitrocdn.com/PbbVSeOwipRjPKhzLbWmrRNvnSxucHBi/assets/images/optimized/rev-0e398dd/www.micrisdental.com/wp-content/uploads/2023/08/Dr-khaled-Profile-BA-16.jpg",
  // },
  // Add more items as needed
];

function TeamProfileGallerySlider({ memberDetailsgallery }) {
  const [filter, setFilter] = useState("Show All");
  const [filteredData, setFilteredData] = useState(portfolioData);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // Extract unique categories from the data and include "Show All"
    const uniqueCategories = [
      "Show All",
      ...new Set(portfolioData.map((item) => item.category)),
    ];
    setCategories(uniqueCategories);
  }, []);

  useEffect(() => {
    if (filter === "Show All") {
      setFilteredData(portfolioData);
    } else {
      setFilteredData(portfolioData.filter((item) => item.category === filter));
    }
  }, [filter]);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  return (
    <section className="work-minimal section-padding sub-bg">
      <div className="container-xxl">
        <div className="sec-head mb-40">
          <h6 className="sub-title mb-15 main-color">Dr's Gallery</h6>
          <div className="bord bord-thin-top d-flex align-items-center"></div>
        </div>

        <div className="row mt-20 mb-20">
          <div className="row align-items-center">
            {memberDetailsgallery?.map((item) => (
              <div
                key={item.id}
                className="col-lg-4 mb-10 align-items-center justify-content-center position-relative galleryBeforeAfter"
              >
                <ReactCompareImage
                  leftImage={item.after_image}
                  rightImage={item.before_image}
                />
                <span>Before</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default TeamProfileGallerySlider;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, FETCH_GALLERY_DATA } from "../api";

// Async thunk to fetch gallery data
export const fetchGalleryData = createAsyncThunk(
  "galleryData/fetchGalleryData",
  async () => {
    try {
      const response = await axios.get(`${BASE_URL}${FETCH_GALLERY_DATA}`);
      return response?.data?.data; // Assuming 'data' contains the gallery information
    } catch (error) {
      throw Error("Failed to fetch gallery data.");
    }
  }
);

// Slice definition for galleryData
const galleryDataSlice = createSlice({
  name: "galleryData",
  initialState: {
    images: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGalleryData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchGalleryData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.images = action.payload; // Store the fetched gallery images
      })
      .addCase(fetchGalleryData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default galleryDataSlice.reducer;

import { Helmet } from "react-helmet";
import Footer from "../../components/dark/home-main/Footer";
import Team from "../../components/dark/team-single/Team";
import { useEffect, useState } from "react";
import TeamProfileGallerySlider from "../../components/dark/portfolio-gallery/TeamProfileGallerySlider";
import { BASE_URL, FETCH_MEMBER_DATA } from "../../app/api";
import { useParams } from "react-router-dom";
export default function PageTeamSingle() {
  const { id } = useParams();
  const [memberDetails, setMemberDetails] = useState(null);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Replace this URL with your actual API endpoint or data fetching logic
    fetch(`${BASE_URL}${FETCH_MEMBER_DATA}/${id}`)
      .then((response) => response.json())
      .then((data) => setMemberDetails(data?.data))
      .catch((error) => console.error("Error fetching memberDetails:", error));
  }, [id]);

  useEffect(() => {
    scrollToTop();
  }, []);

  // console.log("memberDetails: ", memberDetails);

  return (
    <>
      <Helmet>
        <title> {`Our Doctor || ${memberDetails?.member_name}`}</title>
        <link rel="icon" href="/dark/imgs/favicon.ico" />
        <link rel="shortcut icon" href="/dark/imgs/favicon.ico" />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/plugins.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/style.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/satoshi.css"
        />

        <link
          rel="stylesheet"
          type="text/css"
          href="https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght..200;300;400;500;600;700&display=swap"
        />
        <script src="/dark/assets/js/scripts.js"></script>
        <script src="/dark/assets/js/smoother-script.js"></script>
      </Helmet>
      <body>
        <div id="smooth-wrapper">
          <div id="smooth-content">
            <main className="main-bg">
              <Team memberDetails={memberDetails} />
              <TeamProfileGallerySlider
                memberDetailsgallery={memberDetails?.gallery}
              />
              {/* <Skills /> */}
            </main>
            <Footer />
          </div>
        </div>
      </body>
    </>
  );
}

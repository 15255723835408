import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, FETCH_MEMBER_DATA } from "../api";

// Async thunk to fetch member data
export const fetchMemberData = createAsyncThunk(
  "memberData/fetchMemberData",
  async () => {
    try {
      const response = await axios.get(`${BASE_URL}${FETCH_MEMBER_DATA}`);
      return response?.data?.data; // Assuming 'data' contains the member data
    } catch (error) {
      throw Error("Failed to fetch member data.");
    }
  }
);

// Slice definition for memberData
const memberDataSlice = createSlice({
  name: "memberData",
  initialState: {
    members: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMemberData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMemberData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.members = action.payload; // Store the fetched member data
      })
      .addCase(fetchMemberData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default memberDataSlice.reducer;

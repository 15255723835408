// src/app/store.js
import { configureStore } from "@reduxjs/toolkit";
import reviewSlice from "./features/reviewSlice";
import businessSlice from "./features/businessSlice";
import homeBannerSlice from "./features/homeBannerSlice";
import whyChooseUsSlice from "./features/whyChooseUsSlice";
import serviceNavigationSlice from "./features/serviceNavigationSlice";
import memberDataSlice from "./features/memberDataSlice";
import aboutUsDataSlice from "./features/aboutUsDataSlice";
import galleryDataSlice from "./features/galleryDataSlice";
import policyDataSlice from "./features/policyDataSlice";

export const store = configureStore({
  reducer: {
    reviews: reviewSlice,
    business: businessSlice,
    homeBanner: homeBannerSlice,
    whyChooseUs: whyChooseUsSlice,
    serviceNavigation: serviceNavigationSlice,
    memberData: memberDataSlice,
    aboutUsData: aboutUsDataSlice,
    galleryData: galleryDataSlice,
    policyData: policyDataSlice,
  },
});

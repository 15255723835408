import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../../app/api";

function About({ content }) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div>
      <section className="about">
        <div className="container section-padding bord-thin-top">
          <div className="row md-marg">
            <div className="col-lg-6">
              <div className="cont md-mb50">
                <h6 className="sub-title main-color mb-15">Our Mission</h6>
                <div
                  dangerouslySetInnerHTML={{ __html: content?.our_mission }}
                ></div>
                {/* <div className="d-flex align-items-end mt-100">
                  <div>
                    <Link
                      to="/dark/page-contact"
                      className="d-flex align-items-end"
                    >
                      <span className="sub-title nowrap">Contact Us</span>
                      <span className="fz-70 line-height-45 ti-arrow-top-right"></span>
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img-vid">
                <img src={content?.mission_image} alt="" />
                <div className="curv-butn main-bg">
                  <a
                    href={content?.mission_video}
                    target="_blank"
                    className="vid"
                  >
                    <div className="icon">
                      <i className="fas fa-play"></i>
                    </div>
                  </a>
                  <div className="shap-left-top">
                    <svg
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-11 h-11"
                    >
                      <path
                        d="M11 1.54972e-06L0 0L2.38419e-07 11C1.65973e-07 4.92487 4.92487 1.62217e-06 11 1.54972e-06Z"
                        fill="#1a1a1a"
                      ></path>
                    </svg>
                  </div>
                  <div className="shap-right-bottom">
                    <svg
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-11 h-11"
                    >
                      <path
                        d="M11 1.54972e-06L0 0L2.38419e-07 11C1.65973e-07 4.92487 4.92487 1.62217e-06 11 1.54972e-06Z"
                        fill="#1a1a1a"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about">
        <div className="container section-padding bord-thin-top">
          <div className="row md-marg">
            <div className="col-lg-6">
              <div className="img-vid">
                <img src={content?.vision_image} alt="" />
                <div className="curv-butn main-bg">
                  <a
                    href={content?.vision_video}
                    target="_blank"
                    className="vid"
                  >
                    <div className="icon">
                      <i className="fas fa-play"></i>
                    </div>
                  </a>
                  <div className="shap-left-top">
                    <svg
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-11 h-11"
                    >
                      <path
                        d="M11 1.54972e-06L0 0L2.38419e-07 11C1.65973e-07 4.92487 4.92487 1.62217e-06 11 1.54972e-06Z"
                        fill="#1a1a1a"
                      ></path>
                    </svg>
                  </div>
                  <div className="shap-right-bottom">
                    <svg
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-11 h-11"
                    >
                      <path
                        d="M11 1.54972e-06L0 0L2.38419e-07 11C1.65973e-07 4.92487 4.92487 1.62217e-06 11 1.54972e-06Z"
                        fill="#1a1a1a"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="cont md-mb50">
                <h6 className="sub-title main-color mb-15">Our Vision</h6>
                <div
                  dangerouslySetInnerHTML={{ __html: content?.our_vision }}
                ></div>
                {/* <div className="d-flex align-items-end mt-100">
                  <div>
                    <Link
                      to="/dark/page-contact"
                      className="d-flex align-items-end"
                    >
                      <span className="sub-title nowrap">Contact Us</span>
                      <span className="fz-70 line-height-45 ti-arrow-top-right"></span>
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;

"use client";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchMemberData } from "../../../app/features/memberDataSlice";
function Portfolio() {
  const dispatch = useDispatch();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const swiperOptions = {
    modules: [Navigation],
    slidesPerView: 3,
    spaceBetween: 30,
    speed: 500,
    autoPlay: true,
    navigation: {
      nextEl: ".swiper-arrow-control .swiper-button-next",
      prevEl: ".swiper-arrow-control .swiper-button-prev",
    },
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
  };
  const members = useSelector((state) => state.memberData.members);

  useEffect(() => {
    dispatch(fetchMemberData());
  }, [dispatch]);

  return (
    <section className="pb-50">
      <div className="container">
        <div className="sec-head mb-40">
          <h6 className="sub-title main-color mb-25">Our Team</h6>
          <div className="bord pt-25 bord-thin-top d-flex align-items-center">
            <div className="ml-auto">
              <div className="swiper-arrow-control">
                <div className="swiper-button-prev">
                  <span className="ti-arrow-left"></span>
                </div>
                <div className="swiper-button-next">
                  <span className="ti-arrow-right"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="work-swiper-auto">
          <Swiper
            id="content-carousel-container-unq-work"
            className="swiper-container"
            data-swiper="container"
            {...swiperOptions}
          >
            {members?.map((item, i) => (
              <SwiperSlide key={i}>
                <div className="item">
                  <div className="profile-img-container">
                    <img
                      src={item?.member_image}
                      alt="Profile"
                      className={`profile-img ${
                        hoveredIndex === i ? "" : "hidden"
                      }`}
                    />
                    <img
                      src={item?.member_image2}
                      alt="Profile Hover"
                      onMouseEnter={() => setHoveredIndex(i)}
                      onMouseLeave={() => setHoveredIndex(null)}
                      className={`profile-img ${
                        hoveredIndex === i ? "hidden" : ""
                      }`}
                    />
                  </div>
                  <div className="cont d-flex align-items-center mt-30 pb-15 bord-thin-bottom">
                    <div>
                      <h5>{item?.member_name}</h5>
                      <p>{item?.designation}</p>
                    </div>
                    <div className="ml-auto">
                      <Link
                        to={`/our-doctor/doctor-details/${item?.id}`}
                        // to={`/our-doctor/doctor-details/${item?.id}`}
                        className="rmore"
                      >
                        <img
                          src="/dark/assets/imgs/arrow-right.png"
                          alt=""
                          className="icon-img-20"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}

export default Portfolio;

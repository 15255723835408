import { Helmet } from "react-helmet";
import Footer from "../../components/dark/home-main/Footer";
import Header from "../../components/dark/portfolio-gallery/Header";
import Portfolio from "../../components/dark/portfolio-gallery/Portfolio";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGalleryData } from "../../app/features/galleryDataSlice";
export default function PortfolioGallery() {
  const dispatch = useDispatch();
  const [galleryData, setGalleryData] = useState([]);
  useEffect(() => {
    dispatch(fetchGalleryData());
  }, [dispatch]);

  const images = useSelector((state) => state.galleryData.images);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);

  const transformData = (data) => {
    return data.flatMap((item) =>
      item.images.map((image) => ({
        id: item.id,
        category: item.category,
        before: image.before,
        after: image.after,
      }))
    );
  };

  useEffect(() => {
    const flattenedData = transformData(images);
    setGalleryData(flattenedData);
  }, [images]);

  return (
    <>
      <Helmet>
        <title>Our Gallery</title>
        <link rel="icon" href="/dark/imgs/favicon.ico" />
        <link rel="shortcut icon" href="/dark/imgs/favicon.ico" />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/plugins.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/style.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/satoshi.css"
        />

        <link
          rel="stylesheet"
          type="text/css"
          href="https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght..200;300;400;500;600;700&display=swap"
        />
        <script src="/dark/assets/js/scripts.js"></script>
        <script src="/dark/assets/js/smoother-script.js"></script>
      </Helmet>
      <body>
        <div id="smooth-wrapper">
          <div id="smooth-content">
            <main className="main-bg">
              <Header />
              <Portfolio portfolioData={galleryData} />
            </main>
            <Footer />
          </div>
        </div>
      </body>
    </>
  );
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, FETCH_SERVICE_NAVIGATION } from "../api";

// Async thunk to fetch service navigation data
export const fetchServiceNavigationData = createAsyncThunk(
  "serviceNavigation/fetchServiceNavigationData",
  async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}${FETCH_SERVICE_NAVIGATION}`
      );
      return response?.data?.data; // Assuming 'data' contains the navigation data
    } catch (error) {
      throw Error("Failed to fetch service navigation data.");
    }
  }
);

// Slice definition for serviceNavigation
const serviceNavigationSlice = createSlice({
  name: "serviceNavigation",
  initialState: {
    navigationItems: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchServiceNavigationData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchServiceNavigationData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.navigationItems = action.payload;
      })
      .addCase(fetchServiceNavigationData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default serviceNavigationSlice.reducer;

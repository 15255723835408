"use client";
import React, { useEffect, useState } from "react";
import ReactCompareImage from "react-compare-image";
import "./galleryStyle.css";

function Portfolio({ portfolioData }) {
  console.log("galleryData: ", portfolioData);

  const [filter, setFilter] = useState("Show All");
  const [filteredData, setFilteredData] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // Update categories and initial filtered data when portfolioData changes
    if (portfolioData) {
      const uniqueCategories = [
        "Show All",
        ...new Set(portfolioData.map((item) => item.category)),
      ];
      setCategories(uniqueCategories);
      setFilteredData(portfolioData);
    }
  }, [portfolioData]);

  useEffect(() => {
    // Filter data based on the selected category
    if (filter === "Show All") {
      setFilteredData(portfolioData);
    } else {
      setFilteredData(
        portfolioData?.filter((item) => item?.category === filter)
      );
    }
  }, [filter, portfolioData]);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  return (
    <section className="work-minimal section-padding sub-bg">
      <div className="container-xxl">
        <div className="row">
          <div className="filtering col-12 mb-50 text-center">
            <div className="filter">
              <span className="text">Filter By :</span>
              {categories.map((category) => (
                <span
                  key={category}
                  onClick={() => handleFilterChange(category)}
                  className={filter === category ? "active" : ""}
                >
                  {category.charAt(0).toUpperCase() + category.slice(1)}
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className="row mt-20 mb-20">
          <div className="row align-items-center">
            {filteredData?.map((item) => (
              <div
                key={item.id}
                className="col-12 col-md-6 col-lg-4 mb-10 align-items-center justify-content-center position-relative galleryBeforeAfter"
              >
                <ReactCompareImage
                  leftImage={item.after}
                  rightImage={item.before}
                />
                <span>Before</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Portfolio;

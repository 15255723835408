import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, FETCH_ABOUT_US_DATA } from "../api";

// Async thunk to fetch about us data
export const fetchAboutUsData = createAsyncThunk(
  "aboutUsData/fetchAboutUsData",
  async () => {
    try {
      const response = await axios.get(`${BASE_URL}${FETCH_ABOUT_US_DATA}`);
      return response?.data?.data; // Assuming 'data' contains the about us information
    } catch (error) {
      throw Error("Failed to fetch about us data.");
    }
  }
);

// Slice definition for aboutUsData
const aboutUsDataSlice = createSlice({
  name: "aboutUsData",
  initialState: {
    content: null,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAboutUsData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAboutUsData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.content = action.payload; // Store the fetched about us data
      })
      .addCase(fetchAboutUsData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default aboutUsDataSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, FETCH_HOME_BANNER } from "../api";

// Async thunk to fetch home banner data
export const fetchHomeBannerData = createAsyncThunk(
  "homeBanner/fetchHomeBannerData",
  async () => {
    try {
      const response = await axios.get(`${BASE_URL}${FETCH_HOME_BANNER}`);
      return response?.data?.data[0]; // Assuming 'data' contains the banner data
    } catch (error) {
      throw Error("Failed to fetch home banner data.");
    }
  }
);

// Slice definition for homeBanner
const homeBannerSlice = createSlice({
  name: "homeBanner",
  initialState: {
    bannerData: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeBannerData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchHomeBannerData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.bannerData = action.payload;
      })
      .addCase(fetchHomeBannerData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default homeBannerSlice.reducer;

import { Helmet } from "react-helmet";
import Footer from "../../components/dark/home-main/Footer";
import Contact from "../../components/dark/contact/Contact";
import Header from "../../components/dark/contact/Header";
import { useEffect, useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DynamicHeader from "../../components/dark/DynamicHeader";
import axios from "axios";
import { APPOINTMENT_API, BASE_URL } from "../../app/api";
import ToastNotification from "../../Utils/ToastNotification";

export default function AppointmentBook({ businessInfo }) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [time, setTime] = useState("");
  const [message, setMessage] = useState("");

  const options = [
    { value: "Consultation", label: "Consultation" },
    { value: "Pediatric_Consultation", label: "Pediatric Treatment" },
    { value: "Myobrace_Consultation", label: "Myobrace Treatment" },
    { value: "Dental_Implants", label: "Dental Implants" },
    { value: "Habit_Breaking_Appliance", label: "Habit Breaking Appliance" },
    { value: "Invisalign_treatments", label: "Invisalign Treatments" },
    { value: "Wisdom_tooth_removal", label: "Wisdom Tooth Removal" },
    { value: "Ortho-adjustment", label: "Orthodontic Treatments" },
    { value: "Teeth-whitening", label: "Teeth Whitening" },
    { value: "Teeth-cleaning", label: "Teeth Cleaning" },
    { value: "Teeth-extraction", label: "Teeth Extraction" },
    { value: "Quality-brackets", label: "Quality Brackets" },
    { value: "Other-Treatment", label: "Other Treatment" },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !selectedCountry?.value || !mobileNo || !email) {
      ToastNotification("error", "Please fill all the fields!");
      return;
    }

    setLoading(true);

    const appointmentData = {
      name,
      appointment_for: selectedCountry?.value || "",
      mobile_no: mobileNo,
      email,
      date: startDate.toISOString().split("T")[0],
      time,
      message,
    };

    try {
      const response = await axios.post(
        `${BASE_URL}${APPOINTMENT_API}`,
        appointmentData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        ToastNotification("success", "Appointment successfully made!");
        setName("");
        setSelectedCountry(null);
        setMobileNo("");
        setEmail("");
        setStartDate(new Date());
        setMessage("");
        setTime("");
      } else {
        ToastNotification("error", "Failed to make appointment.");
      }
    } catch (error) {
      console.error("Error:", error);
      ToastNotification("error", "Failed to make appointment.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Appointment Book</title>
        <link rel="icon" href="/dark/imgs/favicon.ico" />
        <link rel="shortcut icon" href="/dark/imgs/favicon.ico" />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/plugins.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/style.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/satoshi.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght..200;300;400;500;600;700&display=swap"
        />
        <script src="/dark/assets/js/scripts.js"></script>
        <script src="/dark/assets/js/smoother-script.js"></script>
      </Helmet>
      <body>
        <div id="smooth-wrapper">
          <div id="smooth-content">
            <main className="main-bg">
              <DynamicHeader
                title="MAKE AN APPOINTMENT"
                beforePage="Home"
                currentPage="Appointment"
              />
              <div className="row align-items-center justify-content-center mt-50 mb-25">
                <div className="col-md-4">
                  <div className="appointment-page">
                    <section className="login-register-form-section">
                      <div className="container login-register">
                        <div className="user signupBx">
                          <div className="formBx">
                            <form onSubmit={handleSubmit}>
                              <input
                                type="text"
                                placeholder="Name"
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                              />
                              <div>
                                <Select
                                  className="mb--10 mt-10 border-0 bg-red"
                                  options={options}
                                  onChange={setSelectedCountry}
                                  placeholder="Appointment For"
                                  isSearchable
                                  value={selectedCountry}
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      borderColor: "rgba(255, 255, 255, 0.2)",
                                      borderBottomColor:
                                        "rgba(255, 255, 255, 0.2)",
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.2)",
                                      color: "#FFF",
                                      "&:hover": {
                                        borderColor: "rgba(255, 255, 255, 0.4)",
                                      },
                                    }),
                                    placeholder: (base) => ({
                                      ...base,
                                      color: "#FFF",
                                    }),
                                    singleValue: (base) => ({
                                      ...base,
                                      color: "#FFF",
                                    }),
                                    menu: (base) => ({
                                      ...base,
                                      backgroundColor: "#222",
                                      color: "#FFF",
                                    }),
                                    menuList: (base) => ({
                                      ...base,
                                      backgroundColor: "#222",
                                    }),
                                    option: (
                                      base,
                                      { isSelected, isFocused }
                                    ) => ({
                                      ...base,
                                      backgroundColor: isSelected
                                        ? "#0066cc"
                                        : isFocused
                                        ? "rgba(255, 255, 255, 0.4)"
                                        : "#222",
                                      color: "#FFF",
                                    }),
                                  }}
                                />
                              </div>
                              <input
                                type="text"
                                placeholder="Mobile No."
                                autoComplete="off"
                                onChange={(e) => setMobileNo(e.target.value)}
                                value={mobileNo}
                              />
                              <input
                                type="email"
                                placeholder="Email Address"
                                autoComplete="off"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                              />
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="datepickerContainer">
                                  <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                  />
                                </div>
                                <div className="timepickerContainer">
                                  <input
                                    aria-label="Time"
                                    type="time"
                                    onChange={(e) => setTime(e.target.value)}
                                  />
                                </div>
                              </div>
                              <textarea
                                placeholder="Message"
                                rows={3}
                                onChange={(e) => setMessage(e.target.value)}
                                value={message}
                              />
                              <div className="mt-30">
                                <button
                                  type="submit"
                                  className="butn butn-full butn-bord radius-30"
                                  disabled={loading}
                                >
                                  {loading ? "Booking..." : "Book Appointment"}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </body>
    </>
  );
}

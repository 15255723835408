import React, { useEffect } from "react";

const TawkTo = () => {
  useEffect(() => {
    // Load Tawk.to script
    var s1 = document.createElement("script");
    s1.async = true;
    s1.src = "https://embed.tawk.to/5a4c9703d7591465c7067b14/default";
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");
    document.body.appendChild(s1);

    // Cleanup function
    return () => {
      document.body.removeChild(s1);
    };
  }, []);

  return null; // or you can return some placeholder if needed
};

export default TawkTo;

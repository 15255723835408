'use client';
import React from 'react';

function Portfolio() {
  return (
    <>
      <section className="portfolio-fixed">
        <div className="container-fluid rest">
          <div className="row">
            <div className="col-lg-6 rest">
              <div className="left sticky-item">
                <div
                  id="tab-1"
                  style={{ height: '100vh' }}
                  className="img bg-img"
                  data-background="/dark/assets/imgs/works/stand/1.jpg"
                ></div>
                <div
                  style={{ height: '100vh' }}
                  id="tab-2"
                  className="img bg-img"
                  data-background="/dark/assets/imgs/works/stand/2.jpg"
                ></div>
                <div
                  style={{ height: '100vh' }}
                  id="tab-3"
                  className="img bg-img"
                  data-background="/dark/assets/imgs/works/stand/3.jpg"
                ></div>
                <div
                  id="tab-4"
                  className="img bg-img"
                  data-background="/dark/assets/imgs/works/stand/4.jpg"
                ></div>
                <div
                  id="tab-5"
                  className="img bg-img"
                  data-background="/dark/assets/imgs/works/stand/5.jpg"
                ></div>
                <div
                  id="tab-6"
                  className="img bg-img"
                  data-background="/dark/assets/imgs/works/stand/6.jpg"
                ></div>
              </div>
            </div>
            <div className="col-lg-6 sub-bg right">
              <div className="cont active" data-tab="tab-1">
                <div className="img-hiden">
                  <img src="/dark/assets/imgs/works/stand/1.jpg" alt="" />
                </div>
                <span className="sub-title mb-15 main-color">01. Digital</span>
                <h2 className="mb-15">Luxury Glassware.</h2>
                <div className="row">
                  <div className="col-md-9">
                    <p>
                      We craft premium designs for agencies and global brands
                      around the globe.
                    </p>
                    <div className="vew-all mt-50">
                      <a href="/dark/project3" className="sub-title">
                        Explore More
                        <span>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.922 4.5V11.8125C13.922 11.9244 13.8776 12.0317 13.7985 12.1108C13.7193 12.1899 13.612 12.2344 13.5002 12.2344C13.3883 12.2344 13.281 12.1899 13.2018 12.1108C13.1227 12.0317 13.0783 11.9244 13.0783 11.8125V5.51953L4.79547 13.7953C4.71715 13.8736 4.61092 13.9176 4.50015 13.9176C4.38939 13.9176 4.28316 13.8736 4.20484 13.7953C4.12652 13.717 4.08252 13.6108 4.08252 13.5C4.08252 13.3892 4.12652 13.283 4.20484 13.2047L12.4806 4.92188H6.18765C6.07577 4.92188 5.96846 4.87743 5.88934 4.79831C5.81023 4.71919 5.76578 4.61189 5.76578 4.5C5.76578 4.38811 5.81023 4.28081 5.88934 4.20169C5.96846 4.12257 6.07577 4.07813 6.18765 4.07812H13.5002C13.612 4.07813 13.7193 4.12257 13.7985 4.20169C13.8776 4.28081 13.922 4.38811 13.922 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cont" data-tab="tab-2">
                <div className="img-hiden">
                  <img src="/dark/assets/imgs/works/stand/2.jpg" alt="" />
                </div>
                <span className="sub-title mb-15 main-color">
                  02. Marketing
                </span>
                <h2 className="mb-15">Brand Identity.</h2>
                <div className="row">
                  <div className="col-md-9">
                    <p>
                      We craft premium designs for agencies and global brands
                      around the globe.
                    </p>
                    <div className="vew-all mt-50">
                      <a href="/dark/project3" className="sub-title">
                        Explore More
                        <span>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.922 4.5V11.8125C13.922 11.9244 13.8776 12.0317 13.7985 12.1108C13.7193 12.1899 13.612 12.2344 13.5002 12.2344C13.3883 12.2344 13.281 12.1899 13.2018 12.1108C13.1227 12.0317 13.0783 11.9244 13.0783 11.8125V5.51953L4.79547 13.7953C4.71715 13.8736 4.61092 13.9176 4.50015 13.9176C4.38939 13.9176 4.28316 13.8736 4.20484 13.7953C4.12652 13.717 4.08252 13.6108 4.08252 13.5C4.08252 13.3892 4.12652 13.283 4.20484 13.2047L12.4806 4.92188H6.18765C6.07577 4.92188 5.96846 4.87743 5.88934 4.79831C5.81023 4.71919 5.76578 4.61189 5.76578 4.5C5.76578 4.38811 5.81023 4.28081 5.88934 4.20169C5.96846 4.12257 6.07577 4.07813 6.18765 4.07812H13.5002C13.612 4.07813 13.7193 4.12257 13.7985 4.20169C13.8776 4.28081 13.922 4.38811 13.922 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cont" data-tab="tab-3">
                <div className="img-hiden">
                  <img src="/dark/assets/imgs/works/stand/3.jpg" alt="" />
                </div>
                <span className="sub-title mb-15 main-color">03. Design</span>
                <h2 className="mb-15">Roseville Pottery.</h2>
                <div className="row">
                  <div className="col-md-9">
                    <p>
                      We craft premium designs for agencies and global brands
                      around the globe.
                    </p>
                    <div className="vew-all mt-50">
                      <a href="/dark/project3" className="sub-title">
                        Explore More
                        <span>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.922 4.5V11.8125C13.922 11.9244 13.8776 12.0317 13.7985 12.1108C13.7193 12.1899 13.612 12.2344 13.5002 12.2344C13.3883 12.2344 13.281 12.1899 13.2018 12.1108C13.1227 12.0317 13.0783 11.9244 13.0783 11.8125V5.51953L4.79547 13.7953C4.71715 13.8736 4.61092 13.9176 4.50015 13.9176C4.38939 13.9176 4.28316 13.8736 4.20484 13.7953C4.12652 13.717 4.08252 13.6108 4.08252 13.5C4.08252 13.3892 4.12652 13.283 4.20484 13.2047L12.4806 4.92188H6.18765C6.07577 4.92188 5.96846 4.87743 5.88934 4.79831C5.81023 4.71919 5.76578 4.61189 5.76578 4.5C5.76578 4.38811 5.81023 4.28081 5.88934 4.20169C5.96846 4.12257 6.07577 4.07813 6.18765 4.07812H13.5002C13.612 4.07813 13.7193 4.12257 13.7985 4.20169C13.8776 4.28081 13.922 4.38811 13.922 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cont" data-tab="tab-4">
                <div className="img-hiden">
                  <img src="/dark/assets/imgs/works/stand/4.jpg" alt="" />
                </div>
                <span className="sub-title mb-15 main-color">03. Design</span>
                <h2 className="mb-15">Roseville Pottery.</h2>
                <div className="row">
                  <div className="col-md-9">
                    <p>
                      We craft premium designs for agencies and global brands
                      around the globe.
                    </p>
                    <div className="vew-all mt-50">
                      <a href="/dark/project3" className="sub-title">
                        Explore More
                        <span>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.922 4.5V11.8125C13.922 11.9244 13.8776 12.0317 13.7985 12.1108C13.7193 12.1899 13.612 12.2344 13.5002 12.2344C13.3883 12.2344 13.281 12.1899 13.2018 12.1108C13.1227 12.0317 13.0783 11.9244 13.0783 11.8125V5.51953L4.79547 13.7953C4.71715 13.8736 4.61092 13.9176 4.50015 13.9176C4.38939 13.9176 4.28316 13.8736 4.20484 13.7953C4.12652 13.717 4.08252 13.6108 4.08252 13.5C4.08252 13.3892 4.12652 13.283 4.20484 13.2047L12.4806 4.92188H6.18765C6.07577 4.92188 5.96846 4.87743 5.88934 4.79831C5.81023 4.71919 5.76578 4.61189 5.76578 4.5C5.76578 4.38811 5.81023 4.28081 5.88934 4.20169C5.96846 4.12257 6.07577 4.07813 6.18765 4.07812H13.5002C13.612 4.07813 13.7193 4.12257 13.7985 4.20169C13.8776 4.28081 13.922 4.38811 13.922 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cont" data-tab="tab-5">
                <div className="img-hiden">
                  <img src="/dark/assets/imgs/works/stand/5.jpg" alt="" />
                </div>
                <span className="sub-title mb-15 main-color">03. Design</span>
                <h2 className="mb-15">Roseville Pottery.</h2>
                <div className="row">
                  <div className="col-md-9">
                    <p>
                      We craft premium designs for agencies and global brands
                      around the globe.
                    </p>
                    <div className="vew-all mt-50">
                      <a href="/dark/project3" className="sub-title">
                        Explore More
                        <span>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.922 4.5V11.8125C13.922 11.9244 13.8776 12.0317 13.7985 12.1108C13.7193 12.1899 13.612 12.2344 13.5002 12.2344C13.3883 12.2344 13.281 12.1899 13.2018 12.1108C13.1227 12.0317 13.0783 11.9244 13.0783 11.8125V5.51953L4.79547 13.7953C4.71715 13.8736 4.61092 13.9176 4.50015 13.9176C4.38939 13.9176 4.28316 13.8736 4.20484 13.7953C4.12652 13.717 4.08252 13.6108 4.08252 13.5C4.08252 13.3892 4.12652 13.283 4.20484 13.2047L12.4806 4.92188H6.18765C6.07577 4.92188 5.96846 4.87743 5.88934 4.79831C5.81023 4.71919 5.76578 4.61189 5.76578 4.5C5.76578 4.38811 5.81023 4.28081 5.88934 4.20169C5.96846 4.12257 6.07577 4.07813 6.18765 4.07812H13.5002C13.612 4.07813 13.7193 4.12257 13.7985 4.20169C13.8776 4.28081 13.922 4.38811 13.922 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cont" data-tab="tab-6">
                <div className="img-hiden">
                  <img src="/dark/assets/imgs/works/stand/6.jpg" alt="" />
                </div>
                <span className="sub-title mb-15 main-color">03. Design</span>
                <h2 className="mb-15">Roseville Pottery.</h2>
                <div className="row">
                  <div className="col-md-9">
                    <p>
                      We craft premium designs for agencies and global brands
                      around the globe.
                    </p>
                    <div className="vew-all mt-50">
                      <a href="/dark/project3" className="sub-title">
                        Explore More
                        <span>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.922 4.5V11.8125C13.922 11.9244 13.8776 12.0317 13.7985 12.1108C13.7193 12.1899 13.612 12.2344 13.5002 12.2344C13.3883 12.2344 13.281 12.1899 13.2018 12.1108C13.1227 12.0317 13.0783 11.9244 13.0783 11.8125V5.51953L4.79547 13.7953C4.71715 13.8736 4.61092 13.9176 4.50015 13.9176C4.38939 13.9176 4.28316 13.8736 4.20484 13.7953C4.12652 13.717 4.08252 13.6108 4.08252 13.5C4.08252 13.3892 4.12652 13.283 4.20484 13.2047L12.4806 4.92188H6.18765C6.07577 4.92188 5.96846 4.87743 5.88934 4.79831C5.81023 4.71919 5.76578 4.61189 5.76578 4.5C5.76578 4.38811 5.81023 4.28081 5.88934 4.20169C5.96846 4.12257 6.07577 4.07813 6.18765 4.07812H13.5002C13.612 4.07813 13.7193 4.12257 13.7985 4.20169C13.8776 4.28081 13.922 4.38811 13.922 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Portfolio;

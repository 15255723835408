import React from "react";
import { IMAGE_BASE_URL } from "../../../app/api";

function Team({ memberDetails }) {
  return (
    <section className="team-single">
      <div className="container-fluid">
        <div className="row xlg-marg">
          <div className="col-lg-6">
            <div className="img">
              <img
                src={IMAGE_BASE_URL + memberDetails?.banner_image}
                alt={memberDetails?.member_name}
              />
            </div>
          </div>
          <div className="col-lg-6 valign">
            <div className="cont">
              <h1 className="mt-100">{memberDetails?.member_name}</h1>
              <h6 className="text-u ls1 opacity-8">
                {memberDetails?.designation}
              </h6>
              <div
                className="text main-bg"
                dangerouslySetInnerHTML={{ __html: memberDetails?.short_note }}
              >
                {/* <p className="main-text">
                  Pink salmon cherry salmon combtail gourami frigate mackerel
                  snake mackerel upside-down catfish finback cat shark. Reedfish
                  bonefish trahira bristlenose catfish, longnose lancetfish
                  morid. Wahoo mora deep sea smelt cat shark.
                </p>
                <div className="row md-marg mt-40">
                  <div className="col-sm-6">
                    <p>
                      Pink salmon cherry salmon combtail gourami frigate
                      mackerel snake mackerel upside-down catfish finback cat
                      shark. Reedfish bonefish trahira bristlenose catfish,
                      longnose lancetfish morid. Wahoo mora deep sea smelt cat
                      shark.
                    </p>
                  </div>
                  <div className="col-sm-6">
                    <p>
                      Pink salmon cherry salmon combtail gourami frigate
                      mackerel snake mackerel upside-down catfish finback cat
                      shark. Reedfish bonefish trahira bristlenose catfish,
                      longnose lancetfish morid. Wahoo mora deep sea smelt cat
                      shark.
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;

"use client";
import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";

function VideoSliderHeader({ bannerData, onVideoLoad }) {
  const videoRef = useRef(null);
  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const handleVideoLoad = () => {
        setVideoLoaded(true);
        if (onVideoLoad) onVideoLoad(true); // Notify parent when video has loaded
      };
      video.addEventListener("loadeddata", handleVideoLoad);
      return () => video.removeEventListener("loadeddata", handleVideoLoad);
    }
  }, [onVideoLoad]);

  // Background image loading function
  function loadBackgroundImages() {
    let backgroundImages = document.querySelectorAll("[data-background]");
    backgroundImages.forEach((element) => {
      const image = element.dataset.background;
      if (image) {
        element.style.backgroundImage = `url('${image}')`;
      }
    });
  }

  useEffect(() => {
    loadBackgroundImages();

    const video = videoRef.current;
    if (video) {
      const handleVideoLoad = () => setVideoLoaded(true);
      video.addEventListener("loadeddata", handleVideoLoad);
      return () => video.removeEventListener("loadeddata", handleVideoLoad);
    }
  }, []);

  useEffect(() => {
    if (videoLoaded) {
      // Use requestAnimationFrame for smoother animation handling
      requestAnimationFrame(() => {
        gsap.fromTo(
          ".header",
          { opacity: 0 },
          { opacity: 1, duration: 1.5, ease: "power3.out" }
        );
      });
    }
  }, [videoLoaded]);

  return (
    <div
      className={`header slider slider-prlx ${videoLoaded ? "visible" : ""}`}
      style={{ opacity: videoLoaded ? 1 : 0, transition: "opacity 1.5s ease" }}
    >
      <div className="player-wrapper" data-background={bannerData?.thumbnail}>
        {/* Optional thumbnail */}
        <video
          ref={videoRef}
          autoPlay
          loop
          muted
          preload="auto"
          src={bannerData?.video}
          style={{
            opacity: videoLoaded ? 1 : 0,
            transition: "opacity 1.5s ease",
          }}
        >
          <source src={bannerData?.video} type="video/mp4" />
          <track
            src={bannerData?.video}
            kind="subtitles"
            srcLang="en"
            label="English"
          />
          Your browser does not support the video tag.
        </video>
        <div className="container video-Slider-Content">
          <div className="row caption text-center align-items-center justify-content-center">
            <div className="col-md-10">
              <h3 className="mb-30" data-swiper-parallax="-2000">
                {bannerData?.video_title}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoSliderHeader;

import React, { useState } from "react";
import axios from "axios";
import ToastNotification from "../../../Utils/ToastNotification";
import { BASE_URL, CONTACT_API } from "../../../app/api";
function Contact({ businessInfo }) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation check
    if (!name || !phone || !email || !subject || !message) {
      alert("Please fill all required fields.");
      return;
    }

    const contactData = { name, phone, email, subject, message };

    try {
      const response = await axios.post(BASE_URL + CONTACT_API, contactData, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.status === 201) {
        ToastNotification("success", "Message sent successfully!");
        // Clear the form fields
        setName("");
        setPhone("");
        setEmail("");
        setSubject("");
        setMessage("");
      } else {
        ToastNotification("error", "Failed to send message.");
      }
    } catch (error) {
      console.error("Error:", error);
      ToastNotification("error", "An error occurred. Please try again later.");
    }
  };

  return (
    <section className="contact section-padding sub-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 valign">
            <div className="sec-head md-mb80">
              <h2 className="text-u ls1 d-rotate wow">
                <span className="rotate-text">We'd Love to Hear From You</span>
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: businessInfo?.business_desc,
                }}
              />

              <div className="row">
                <div className="col-md-6">
                  <div className="morinfo mt-30">
                    <h6 className="mb-15">Address</h6>
                    <p>{businessInfo?.address_1}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="morinfo mt-30">
                    <h6 className="mb-15">Email</h6>
                    <p>{businessInfo?.company_email}</p>
                  </div>
                </div>
              </div>
              <div className="phone fz-30 fw-600 mt-30 underline main-color">
                <a href="#0">{businessInfo?.office_phone}</a>
              </div>
              <ul className="rest social-text d-flex mt-60">
                {businessInfo?.facebook_link && (
                  <li className="mr-30">
                    <a
                      href={businessInfo?.facebook_link}
                      className="hover-this"
                    >
                      <span className="hover-anim">Facebook</span>
                    </a>
                  </li>
                )}
                {businessInfo?.twitter_link && (
                  <li className="mr-30">
                    <a href={businessInfo?.twitter_link} className="hover-this">
                      <span className="hover-anim">Twitter</span>
                    </a>
                  </li>
                )}
                {businessInfo?.linkedin_link && (
                  <li className="mr-30">
                    <a
                      href={businessInfo?.linkedin_link}
                      className="hover-this"
                    >
                      <span className="hover-anim">LinkedIn</span>
                    </a>
                  </li>
                )}
                {businessInfo?.insta_link && (
                  <li>
                    <a href={businessInfo?.insta_link} className="hover-this">
                      <span className="hover-anim">Instagram</span>
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="col-lg-6 offset-lg-1 valign">
            <div className="full-width">
              <div className="sec-head mb-50">
                <h3 className="text-u ls1">
                  Send a <span className="fw-200">message</span>
                </h3>
              </div>
              <form id="contact-form" onSubmit={handleSubmit}>
                <div className="controls row">
                  <div className="col-lg-6">
                    <div className="form-group mb-30">
                      <input
                        id="form_name"
                        type="text"
                        name="name"
                        placeholder="Name"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-30">
                      <input
                        id="form_phone"
                        type="tel"
                        name="phone"
                        placeholder="Phone"
                        required
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-30">
                      <input
                        id="form_email"
                        type="email"
                        name="email"
                        placeholder="Email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group mb-30">
                      <input
                        id="form_subject"
                        type="text"
                        name="subject"
                        placeholder="Subject"
                        required
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        id="form_message"
                        name="message"
                        placeholder="Message"
                        rows="4"
                        required
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="mt-30">
                      <button
                        type="submit"
                        className="butn butn-full butn-bord radius-30"
                      >
                        <span className="text">Let&apos;s Talk</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;

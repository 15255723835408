import { Helmet } from "react-helmet";
import Footer from "../../components/dark/home-main/Footer";
import Services2 from "../../components/dark/digital-agency/Services";
import { useEffect } from "react";
import Header from "../../components/dark/about2/Header";
import { useDispatch, useSelector } from "react-redux";
import { fetchServiceNavigationData } from "../../app/features/serviceNavigationSlice";
import DynamicHeader from "../../components/dark/DynamicHeader";
import { Link } from "react-router-dom";

export default function Services() {
  const dispatch = useDispatch();
  const navigationItems = useSelector(
    (state) => state.serviceNavigation.navigationItems
  );
  useEffect(() => {
    dispatch(fetchServiceNavigationData());
  }, [dispatch]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <Helmet>
        <title>Our services</title>
        <link rel="icon" href="/dark/imgs/favicon.ico" />
        <link rel="shortcut icon" href="/dark/imgs/favicon.ico" />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/plugins.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/style.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/satoshi.css"
        />

        <link
          rel="stylesheet"
          type="text/css"
          href="https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght..200;300;400;500;600;700&display=swap"
        />
        <script src="/dark/assets/js/scripts.js"></script>
        <script src="/dark/assets/js/smoother-script.js"></script>
      </Helmet>
      <body>
        <div id="smooth-wrapper">
          <div id="smooth-content">
            <main className="main-bg">
              {/* <Intro title="About Us" /> */}
              <DynamicHeader
                title="Our Services"
                beforePage="Home"
                currentPage="Our Services"
              />
              <section className="services pb-50 mt-50">
                <div className="container">
                  <div className="sec-head mb-40">
                    <h6 className="sub-title main-color mb-25">
                      Our All Services
                    </h6>
                    <div className="bord pt-25 bord-thin-top d-flex align-items-center">
                      <div className="ml-auto">
                        {/* <a href="/dark/page-services2" className="go-more">
                          <span className="text">View all services</span>
                          <span className="icon ti-arrow-top-right"></span>
                        </a> */}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {navigationItems?.map((item, i) => (
                      <div key={i} className="col-lg-4 mb-30">
                        <div className="item-box radius-15 md-mb50">
                          <div className="icon mb-40 opacity-5">
                            <img
                              src={item?.service_icon}
                              alt={item?.service_slug}
                            />
                          </div>
                          <h5 className="mb-15">{item?.service_name}</h5>
                          <p>{item?.short_desc}</p>
                          <Link
                            to={`/our-services/services-details/${item?.service_slug}`}
                            className="rmore mt-30"
                          >
                            <span className="sub-title">Read More</span>
                            <img
                              src="/dark/assets/imgs/arrow-right.png"
                              alt=""
                              className="icon-img-20 ml-5"
                            />
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </main>
            <Footer />
          </div>
        </div>
      </body>
    </>
  );
}

import React from "react";
import data from "../../../data/services.json";
import { Link } from "react-router-dom";
function Services2({ getRelatedServiceProduct }) {
  return getRelatedServiceProduct?.length > 0 ? (
    <section className="services">
      <div className="container">
        <div className="sec-head mb-25 mt-50">
          <h6 className="sub-title main-color mb-25">Related Services</h6>
          <div className="bord pt-25 bord-thin-top d-flex align-items-center">
            <div className="ml-auto">
              <Link to="/our-services" className="go-more">
                <span className="text">View all services</span>
                <span className="icon ti-arrow-top-right"></span>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          {getRelatedServiceProduct?.slice(0, 3).map((item, i) => (
            <div key={i} className="col-lg-4">
              <div className="item-box radius-15 md-mb30">
                <div className="icon mb-40 opacity-5">
                  <img src={item?.service_icon} alt="" />
                </div>
                <h5 className="mb-15">{item.service_name}</h5>
                <p>{item.short_desc}</p>
                <Link
                  to={`/our-services/services-details/${item?.service_slug}`}
                  className="rmore mt-30"
                >
                  <span className="sub-title">Read More</span>
                  <img
                    src="/dark/assets/imgs/arrow-right.png"
                    alt=""
                    className="icon-img-20 ml-5"
                  />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  ) : null;
}

export default Services2;

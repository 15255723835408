import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import Footer from "../../components/dark/home-main/Footer";
import Lines from "../../components/dark/common/Lines";
import ProgressScroll from "../../components/dark/common/ProgressScroll";
import Cursor from "../../components/dark/common/cusor";
import LoadingScreen from "../../components/dark/common/loader";
import Header from "../../components/dark/services2/Header";
import Services from "../../components/dark/services-details/Services";
import Services2 from "../../components/dark/services-details/Services2";
import { BASE_URL, SERVICE_DETAILS_API } from "../../app/api";
import { useSelector } from "react-redux";
import DynamicHelmate from "../../components/dark/Helmet/DynamicHelmate";

export default function PageServicesDetails() {
  const { slug } = useParams();
  const [service, setService] = useState(null);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    // Replace this URL with your actual API endpoint or data fetching logic
    fetch(`${BASE_URL}${SERVICE_DETAILS_API}/${slug}`)
      .then((response) => response.json())
      .then((data) => setService(data?.data))
      .catch((error) => console.error("Error fetching service:", error));
  }, [slug]);

  const navigationItems = useSelector(
    (state) => state.serviceNavigation.navigationItems
  );

  const getRelatedServiceId = navigationItems?.filter((ele) => {
    return ele?.id === service?.id;
  });

  return (
    <>
      <DynamicHelmate metacontent={service} />
      <body>
        <LoadingScreen />
        <Cursor />
        <ProgressScroll />
        <Lines />
        <div id="smooth-wrapper">
          <div id="smooth-content">
            <main className="main-bg">
              <Header service={service} />
              <Services service={service} />
              <Services2
                getRelatedServiceProduct={
                  getRelatedServiceId[0]?.child_services
                }
              />
            </main>
            <Footer />
          </div>
        </div>
      </body>
    </>
  );
}

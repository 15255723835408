// HelmetComponent.js
import React from "react";
import { Helmet } from "react-helmet";

const DynamicHelmate = ({ metacontent }) => (
  <Helmet>
    <title>{metacontent?.meta_title}</title>
    <meta name="keywords" content={metacontent?.meta_keyword} />
    <meta name="description" content={metacontent?.meta_desc} />
    <link rel="icon" href="/dark/imgs/favicon.ico" />
    <link rel="shortcut icon" href="/dark/imgs/favicon.ico" />
    <link
      rel="stylesheet"
      type="text/css"
      href="/dark/assets/css/plugins.css"
    />
    <link rel="stylesheet" type="text/css" href="/dark/assets/css/style.css" />
    <link
      rel="stylesheet"
      type="text/css"
      href="/dark/assets/css/satoshi.css"
    />

    <link
      rel="stylesheet"
      type="text/css"
      href="https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap"
    />
    <link
      rel="stylesheet"
      type="text/css"
      href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght..200;300;400;500;600;700&display=swap"
    />
    <script src="/dark/assets/js/scripts.js"></script>
    <script src="/dark/assets/js/smoother-script.js"></script>
  </Helmet>
);

export default DynamicHelmate;

import { Helmet } from "react-helmet";
import React, { useState } from "react";
import Footer from "../../components/dark/home-main/Footer";
import Portfolio from "../../components/dark/creative-agency/Portfolio";
import Services from "../../components/dark/home-main/Services";
import Services2 from "../../components/dark/digital-agency/Services";
import Testimonials from "../../components/dark/home-main/Testimonials";
import VideoSliderHeader from "../../components/dark/modern-agency/VideoSliderHeader";
import { useDispatch, useSelector } from "react-redux";
import { fetchWhyChooseUsData } from "../../app/features/whyChooseUsSlice";
import { useEffect } from "react";
import { fetchServiceNavigationData } from "../../app/features/serviceNavigationSlice";
import { fetchMemberData } from "../../app/features/memberDataSlice";

export default function HomeMain() {
  const dispatch = useDispatch();
  const [bannerLoaded, setBannerLoaded] = useState(false);
  const bannerData = useSelector((state) => state.homeBanner.bannerData);
  const reasons = useSelector((state) => state.whyChooseUs.reasons);
  const navigationItems = useSelector(
    (state) => state.serviceNavigation.navigationItems
  );
  useEffect(() => {
    dispatch(fetchWhyChooseUsData());
  }, [dispatch]);

  const status = useSelector((state) => state.homeBanner.status);
  const error = useSelector((state) => state.homeBanner.error);

  if (status === "loading") return <p></p>;
  if (status === "failed") return <p></p>;

  console.log("bannerData: ", bannerData);

  return (
    <>
      <Helmet>
        <title>
          Solanki Dental Clinic in Sharjah, Dental Clinics in UAE, Best Dentist
          in UAE, Dentist in Sharjah, Dental Centre in sharjah, Solanki dental
          centre{" "}
        </title>
        <meta
          name="description"
          content="Sharjah Dentist | Orthodontics, Pediatric Dentistry & More: Solanki Dental Clinic is your one-stop shop for all your dental needs in Sharjah. We offer expert care using advanced technology. Call us today!"
        />
        <meta
          name="keywords"
          content="Sharjah Dentist | Orthodontics, Pediatric Dentistry & More: Solanki Dental Clinic is your one-stop shop for all your dental needs in Sharjah. We offer expert care using advanced technology. Call us today!"
        />
      </Helmet>
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <main className="main-bg o-hidden">
            <VideoSliderHeader
              bannerData={bannerData}
              onVideoLoad={setBannerLoaded}
            />
            {bannerLoaded && (
              <>
                <Services reasons={reasons} />
                <Services2 navigationItems={navigationItems} />
                <Portfolio />
                <Footer />
              </>
            )}
            {/* <Testimonials /> */}
          </main>
        </div>
      </div>
    </>
  );
}

const WhatsappComp = () => {
  return (
    <div className="whatsapp-wrap">
      <a
        target="_blank"
        href="https://api.whatsapp.com/send?phone=+971504816719&amp;text=Hello%2C%20Solanki%20Dental%20Centre%2C%20I%20would%20like%20to%20take%20an%20appointment%20at"
      >
        <img src="/dark/assets/imgs/icon-img/whatsapp.png" alt="Whatsapp" />
      </a>
    </div>
  );
};

export default WhatsappComp;

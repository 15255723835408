import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, FETCH_BUSINESS } from "../api";

export const fetchBusinessData = createAsyncThunk(
  "business/fetchBusinessData",
  async () => {
    try {
      const response = await axios.get(`${BASE_URL}${FETCH_BUSINESS}`);
      return response?.data?.data; // Assuming you want to extract the 'data' field
    } catch (error) {
      throw Error("Failed to fetch business data.");
    }
  }
);

const businessSlice = createSlice({
  name: "business",
  initialState: {
    businessInfo: null,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBusinessData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBusinessData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.businessInfo = action.payload;
      })
      .addCase(fetchBusinessData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default businessSlice.reducer;

import { Helmet } from "react-helmet";
import Footer from "../../components/dark/home-main/Footer";
import Header from "../../components/dark/team/Header";
import Team from "../../components/dark/team/Team";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMemberData } from "../../app/features/memberDataSlice";
export default function PageTeam() {
  const dispatch = useDispatch();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);

  const members = useSelector((state) => state.memberData.members);

  useEffect(() => {
    dispatch(fetchMemberData());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Our Doctors</title>
        <link rel="icon" href="/dark/imgs/favicon.ico" />
        <link rel="shortcut icon" href="/dark/imgs/favicon.ico" />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/plugins.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/style.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/satoshi.css"
        />

        <link
          rel="stylesheet"
          type="text/css"
          href="https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght..200;300;400;500;600;700&display=swap"
        />
        <script src="/dark/assets/js/scripts.js"></script>
        <script src="/dark/assets/js/smoother-script.js"></script>
      </Helmet>
      <body>
        <div id="smooth-wrapper">
          <div id="smooth-content">
            <main className="main-bg">
              <Header />
              <Team members={members} />
            </main>
            <Footer />
          </div>
        </div>
      </body>
    </>
  );
}

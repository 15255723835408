import { Helmet } from "react-helmet";
import Footer from "../../components/dark/home-main/Footer";
import Intro from "../../components/dark/about2/Intro";
import Numbers from "../../components/dark/about2/Numbers";
import About from "../../components/dark/about2/About";
import Portfolio from "../../components/dark/creative-agency/Portfolio";
import Testimonials from "../../components/dark/home-main/Testimonials";
import { useEffect } from "react";
import Header from "../../components/dark/about2/Header";
import { useDispatch, useSelector } from "react-redux";
import { fetchAboutUsData } from "../../app/features/aboutUsDataSlice";
import { fetchPolicyData } from "../../app/features/policyDataSlice";
import DynamicHeader from "../../components/dark/DynamicHeader";

export default function PrivecyPolicy() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPolicyData());
  }, [dispatch]);
  const policies = useSelector((state) => state.policyData.policies);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <Helmet>
        <title>Privecy Policy</title>
        <link rel="icon" href="/dark/imgs/favicon.ico" />
        <link rel="shortcut icon" href="/dark/imgs/favicon.ico" />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/plugins.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/style.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/satoshi.css"
        />

        <link
          rel="stylesheet"
          type="text/css"
          href="https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght..200;300;400;500;600;700&display=swap"
        />
        <script src="/dark/assets/js/scripts.js"></script>
        <script src="/dark/assets/js/smoother-script.js"></script>
      </Helmet>
      <body>
        <div id="smooth-wrapper">
          <div id="smooth-content">
            <main className="main-bg">
              {/* <Intro title="About Us" /> */}
              <DynamicHeader
                title="Privecy Policy"
                beforePage="Home"
                currentPage="Privecy Policy"
              />

              <div className="container mt-50">
                <div
                  dangerouslySetInnerHTML={{ __html: policies?.privacy }}
                ></div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </body>
    </>
  );
}
